<template>
  <account-code-select v-model="sCodeAccount" @change="onChange" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Currency, CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import AccountCodeSelect from "@/modules/accounts/components/AccountCodeSelect.vue";
import { Account } from "@planetadeleste/vue-mc-gw";

@Component({
  components: { AccountCodeSelect },
})
export default class CurrencyAccountCode extends Vue {
  @Prop({ type: Object, default: () => ({}) }) readonly value!:
    | CurrencyData
    | Currency;

  get currency() {
    return this.value instanceof Currency
      ? this.value
      : new Currency(this.value);
  }

  get sCodeAccount(): string {
    return this.currency.get("code_account", "");
  }

  set sCodeAccount(sValue: string) {
    this.currency.set("code_account", sValue);
    this.$emit("change", sValue);
  }

  onChange(obModel: Account | null) {
    if (obModel) {
      this.$emit("change", obModel.code);
    }
  }
}
</script>
